<script setup lang="ts">
const route = useRoute()
const props = defineProps<{
  menuItems: { title: string; to: string }[]
}>()

const { user } = useUser()
const isActiveMenuItem = (href: string | null) => {
  return href && route.path.includes(href)
}
</script>

<template>
  <div v-if="user" class="space-y-8">
    <ul class="mt-1 list-none">
      <li v-for="item of props.menuItems" :key="item.to">
        <NuxtLinkLocale
          :to="item.to"
          class="block py-1.5 pl-10"
          :class="isActiveMenuItem(item.to) ? 'font-bold' : ''"
        >
          {{ item.title }}
        </NuxtLinkLocale>
      </li>
    </ul>
  </div>
</template>
