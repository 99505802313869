<script setup lang="ts">
const { user, reloadUser } = useUser()

await reloadUser()

const localePath = useLocalePath()

if (!user.value) {
  await navigateTo(localePath('/auth/login'))
  throw new Error('User not found')
}

if (!user.value.onboardingComplete) {
  await navigateTo(localePath('/onboarding'))
  throw new Error('Onboarding not complete')
}
const { t } = useTranslations()

const menuItems = computed(() => {
  return [
    {
      title: t('settings.menu.team.general'),
      to: '/account/profile',
    },
    {
      title: t('settings.menu.team.billing'),
      to: '/account/billing',
    },
  ]
})
</script>

<template>
  <SaasNavBar />

  <main class="min-h-[calc(100vh-12rem)]">
    <div class="container max-w-6xl py-8">
      <div class="flex flex-col items-start gap-8 md:flex-row">
        <div class="w-full md:max-w-[200px]">
          <SaasSettingsMenu :menu-items="menuItems" />
        </div>

        <div class="flex-1">
          <!-- Nested route -->
          <slot />
        </div>
      </div>
    </div>
  </main>

  <SaasFooter />
</template>
