<script setup lang="ts">
import { LanguagesIcon, LogOutIcon, SettingsIcon } from 'lucide-vue-next'

const { user, logout } = useUser()
</script>

<template>
  <DropdownMenu v-if="user">
    <DropdownMenuTrigger as-child>
      <button
        class="rounded-full outline-none focus-visible:ring-2 focus-visible:ring-primary"
      >
        <UserAvatar :name="user.name ?? ''" :avatar-url="user.avatarUrl" />
      </button>
    </DropdownMenuTrigger>

    <DropdownMenuContent align="end">
      <DropdownMenuLabel>
        {{ user.name }}
        <span class="block text-xs font-normal opacity-70">{{
          user.email
        }}</span>
      </DropdownMenuLabel>

      <DropdownMenuSeparator />

      <!-- Color mode selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <ColorModeToggleIcon />
          <div class="ml-2">
            {{ $t('dashboard.userMenu.colorMode') }}
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <ColorModeToggleRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <!-- Locale selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <LanguagesIcon class="size-4" />

          <div class="ml-2">
            {{ $t('dashboard.userMenu.language') }}
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <LocaleSwitchRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <DropdownMenuItem as-child>
        <NuxtLinkLocale to="/app/settings/account/general">
          <SettingsIcon class="mr-2 size-4" />
          {{ $t('dashboard.userMenu.accountSettings') }}
        </NuxtLinkLocale>
      </DropdownMenuItem>

      <DropdownMenuItem @click="logout">
        <LogOutIcon class="mr-2 size-4" />
        {{ $t('dashboard.userMenu.logout') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
